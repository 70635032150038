import React, { useState } from "react"

/** Auxiliaries */
import { Button, Flex, Text } from "../../auxiliaries"

/** Utils */
import { CLEAR_100, DARK_900, GRAY_900 } from "../../../utils/layout/COLORS"

/** Locales */
import locales from "./locales/Footer.json"

const LanguageSelector = ({ lang, device, onUpdateLang }) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)

  const toggleDropdown = () => {
    setIsDropdownOpened(prev => !prev)
  }

  return (
    <Flex position={"relative"}>
      <Button
        bgColor={DARK_900}
        px={"0"}
        py={device === "mobile" && "4.347826087vw"}
        onClick={toggleDropdown}
      >
        <Text
          fontFamily="Raleway"
          fontSize={device === "mobile" ? "3.8647vw" : "13px"}
          color={CLEAR_100}
        >
          {locales[lang][`${lang}_LABEL`]}
        </Text>
      </Button>
      {isDropdownOpened && (
        <Flex
          bgColor={GRAY_900}
          position={"absolute"}
          direction={"column"}
          top={"100%"}
          left={"0.833333333vw"}
        >
          <Text
            fontFamily="Raleway"
            fontSize={device === "mobile" ? "3.8647vw" : "0.833333333vw"}
            color={CLEAR_100}
            padding={"0.833333333vw"}
            cursor={"pointer"}
            onClick={() => onUpdateLang("PT")}
          >
            {locales[lang]["PT_LABEL"]}
          </Text>
          {/* <Text
            fontFamily="Raleway"
            fontSize={device === "mobile" ? "3.8647vw" : "0.833333333vw"}
            color={CLEAR_100}
            padding={"0.833333333vw"}
            cursor={"pointer"}
            onClick={() => onUpdateLang("EN")}
          >
            {locales[lang]["EN_LABEL"]}
          </Text> */}
        </Flex>
      )}
    </Flex>
  )
}

export default LanguageSelector

import styled from 'styled-components';

import { Spacing, WidthProps } from '.';

export default styled.div`
  display: flex;
  flex: ${({ flex }) => flex || 'unset'};
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'unset'};
  align-items: ${({ alignItems }) => alignItems || 'unset'};
  background-color: ${({ bgColor }) => bgColor};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  position: ${({ position }) => position};
  order: ${({ order }) => order};
  gap: ${({ gap }) => gap};

  ${Spacing};
  ${WidthProps};
`;

import React, { createContext, useContext, useState, useEffect } from "react"
import { window } from "browser-monads"

const getScrollY = () => window.scrollY

const scrollPositionContext = createContext({})

const ScrollPositionProvider = ({ children }) => {
  const [scroll, setscroll] = useState(getScrollY())

  const onWindowScroll = () => setscroll(getScrollY())

  useEffect(() => {
    window.addEventListener("scroll", onWindowScroll)
    return () => window.removeEventListener("scroll", onWindowScroll)
  }, [])

  return (
    <scrollPositionContext.Provider value={{ scroll }}>
      {children}
    </scrollPositionContext.Provider>
  )
}

const useScrollPosition = () => {
  const { scroll } = useContext(scrollPositionContext)
  return scroll
}

export { ScrollPositionProvider, useScrollPosition }

import styled from "styled-components"
import { rgba } from "polished"

import { Spacing, WidthProps } from "."

export default styled.button`
  background-color: ${({ outline, bgColor }) =>
    outline ? "transparent" : bgColor};
  box-shadow: ${({ bgColor, boxShadow }) =>
    boxShadow && bgColor && `0 6px 15px 0 ${rgba(bgColor, 0.3)}`};
  cursor: pointer;

  ${Spacing};
  ${WidthProps};

  padding-left: ${({ px }) => px || "1.388888889vw"};
  padding-right: ${({ px }) => px || "1.388888889vw"};
  padding-bottom: ${({ py }) => py || "0.833333333vw"};
  padding-top: ${({ py }) => py || "0.833333333vw"};
`

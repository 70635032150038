import React, { useRef } from "react"

/** Auxiliaries */
import { Button, Container, Flex, Icon, Link, Text } from "../../auxiliaries"

/** Utils */
import { CLEAR_100, DARK_900 } from "../../../utils/layout/COLORS"

/** Locales */
import locales from "./locales/Navbar.json"
import { navigate } from "gatsby"

const Desktop = ({ lang = "PT" }) => {
  const ref = useRef()

  const onClick = link => {
    const element = document.getElementById(link)

    if (ref && ref.current) {
      window.scrollTo({
        top: element.offsetTop - ref.current.offsetHeight - 16,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  return (
    <Flex position="relative" height="8.194444444vw">
      <Flex
        ref={ref}
        position="fixed"
        bgColor={CLEAR_100}
        style={{
          left: "0px",
          top: "0px",
          zIndex: "10",
          width: "100%",
        }}
      >
        <Container
          justifyContent="space-between"
          alignItems="center"
          py="25px"
          mx="auto"
        >
          <Icon
            name="logo"
            height="50px"
            width="50px"
            cursor="pointer"
            onClick={() => navigate("/")}
          />
          <Flex gap="9.722222222vw" alignItems="center">
            <Flex gap="2vw">
              {/* <Text
                onClick={() => navigate("/vps")}
                cursor="pointer"
                fontFamily="Raleway"
                fontSize="14px"
                weight="medium"
              >
                {locales[lang]["VPS"]}
              </Text> */}
              <Text
                onClick={() => navigate("/baremetal")}
                cursor="pointer"
                fontFamily="Raleway"
                fontSize="14px"
                weight="medium"
              >
                {locales[lang]["BAREMETAL"]}
              </Text>
              <Text
                onClick={() => navigate("/colocation")}
                cursor="pointer"
                fontFamily="Raleway"
                fontSize="14px"
                weight="medium"
              >
                {locales[lang]["COLOCATION"]}
              </Text>
            </Flex>
            <Flex gap="1.388888889vw">
              <Link href="https://enxadahost.com/members/login" target="_BLANK">
                <Button bgColor={DARK_900}>
                  <Text
                    fontFamily="Raleway"
                    fontSize="14px"
                    weight="bold"
                    color={CLEAR_100}
                  >
                    {locales[lang]["LOGIN"]}
                  </Text>
                </Button>
              </Link>
              <Link href="https://enxadahost.com/members/register.php" target="_BLANK">
                <Button bgColor={CLEAR_100}>
                  <Text
                    fontFamily="Raleway"
                    fontSize="14px"
                    weight="bold"
                    color={DARK_900}
                  >
                    {locales[lang]["SIGNUP"]}
                  </Text>
                </Button>
              </Link>
            </Flex>
          </Flex>
        </Container>
      </Flex>
    </Flex>
  )
}

export default Desktop

import styled from "styled-components"

import { DARK_900 } from "../../../utils/layout/COLORS"
import { HeightProps, Spacing, WidthProps } from ".."

const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  black: 900,
}

const Text = styled.h1`
  color: ${({ color }) => color || DARK_900};
  font-size: ${({ fontSize }) => fontSize || "0.972222222vw"};
  font-family: ${({ fontFamily }) =>
    `'${fontFamily || "Open Sans"}', sans-serif`};
  font-weight: ${({ weight }) => fontWeights[weight || "regular"]};
  text-align: ${({ textAlign }) => textAlign};
  text-shadow: ${({ textShadow }) => textShadow && "0 0 6px rgba(0,0,0,.2)"};

  ${Spacing}
  ${WidthProps};
  ${HeightProps};
`

export default Text

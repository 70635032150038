export const transformStyleToStyleObj = style => {
  let styleObj = {}

  const props = style.split(";")
  props.forEach(prop => {
    const [name, value] = prop.split(":")
    if (!!name && !!value) {
      styleObj[name] = value
    }
  })

  return styleObj
}

export const transformToCurrency = (val, currency = "R$") => `${currency} ${Number(val).toFixed(2).replace('.', ',')}`
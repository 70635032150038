import { HeightProps, Spacing, WidthDelimiter, WidthProps } from "./Mixins"
import Flex from "./Flex"
import Container from "./Container"
import Image from "./Image"
import Icon from "./Icon"
import Button from "./Button"
import Link from "./Link"
import Select from "./Select"
import { Text, Title } from "./Typography"

const Mixins = { HeightProps, Spacing, WidthDelimiter, WidthProps }
const Typography = { Text, Title }

export {
  Button,
  Container,
  Flex,
  HeightProps,
  Icon,
  Image,
  Link,
  Mixins,
  Select,
  Spacing,
  Text,
  Title,
  Typography,
  WidthDelimiter,
  WidthProps,
}

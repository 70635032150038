import { keyframes } from "styled-components"

export default keyframes`
  0% {
    position: absolute;
    top: 0;
    left: 0;
  }

  50% {
    position: absolute;
    top: 40%;
    opacity: 0;

    & h1,
    & p {
      opacity: 0;
    }
  }

  70% {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  
  100% {
    position: absolute;
    top: 0;
    left: 0;
  }
`

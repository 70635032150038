import React from "react"
import Parser from "html-react-parser"

/** Auxiliaries */
import { Container, Flex, Link, Text } from "../../auxiliaries"

/** Utils */
import { CLEAR_100, DARK_900 } from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang, useUpdateLang } from "../../../utils/layout/LanguageContext"

/** Components */
import LanguageSelector from "./LanguageSelector"

/** Locales */
import locales from "./locales/Footer.json"

const Footer = () => {
  const device = useDeviceType()
  const lang = useLang()
  const updateLang = useUpdateLang()

  if (!device) return null

  const items = [
    {
      title: locales[lang]["ABOUT_US"],
      items: [
        {
          label: locales[lang]["ABOUT"],
          link: "/",
        },
        {
          label: locales[lang]["TERMS_OF_SERVICE"],
          link: "https://enx.host/termos-de-uso",
        },
      ],
    },
    {
      title: locales[lang]["PRODUCTS"],
      items: [
        {
          label: locales[lang]["HOST"],
          link: "https://enx.host/",
        },
        {
          label: locales[lang]["PROVIDER"],
          link: "https://enx.net.br/",
        },
        {
          label: locales[lang]["ENXADA"],
          link: "https://enxadahost.com/",
        },
      ],
    },
    {
      title: locales[lang]["CONTACT_AND_HELP"],
      items: [
        {
          label: locales[lang]["CONTACT"],
          link: "#",
        },
        {
          label: locales[lang]["HELP_CENTER"],
          link: "#",
        },
      ],
    },
    {
      title: locales[lang]["SOCIAL_MEDIA"],
      items: [
        {
          label: locales[lang]["FACEBOOK"],
          link: "#",
        },
        {
          label: locales[lang]["INSTAGRAM"],
          link: "#",
        },
        {
          label: locales[lang]["LINKEDIN"],
          link: "https://br.linkedin.com/company/enxservices/",
        },
      ],
    },
  ]

  return (
    <Flex
      bgColor={DARK_900}
      pt={device === "mobile" ? "16.425120773vw" : "50px"}
      pb={device === "mobile" ? "15.458937198vw" : "50px"}
    >
      <Container direction="column" mx="auto">
        <Flex
          direction={device === "mobile" ? "column" : "row"}
          gap={device === "mobile" ? "15.458937198vw" : "11.875vw"}
          mb={device === "mobile" ? "19.323671498vw" : "90px"}
        >
          {items.map(item => (
            <Flex
              key={item.title}
              direction="column"
              gap={device === "mobile" ? "10.628019324vw" : "2.222222222vw"}
            >
              <Text
                fontFamily="Raleway"
                fontSize={device === "mobile" ? "5.7971vw" : "16px"}
                weight="black"
                color={CLEAR_100}
              >
                {item.title}
              </Text>
              <Flex
                direction="column"
                gap={device === "mobile" ? "7.729468599vw" : "1.666666667vw"}
              >
                {item.items.map(({ label, link }) => (
                  <Link key={label} href={link}>
                    <Text
                      fontFamily="Raleway"
                      fontSize={device === "mobile" ? "3.8647vw" : "15px"}
                      color={CLEAR_100}
                    >
                      {label}
                    </Text>
                  </Link>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
        <Flex mb={"1.6666667vw"}>
          <LanguageSelector
            device={device}
            lang={lang}
            onUpdateLang={updateLang}
          />
        </Flex>
        <Flex
          direction={device === "mobile" ? "column-reverse" : "row"}
          justifyContent="space-between"
          alignItems={device === "mobile" && "center"}
          gap={device === "mobile" && "5.7971vw"}
        >
          <Text
            fontSize={device === "mobile" ? "3.8647vw" : "13px"}
            textAlign={device === "mobile" && "center"}
            color={CLEAR_100}
          >
            © {new Date().getFullYear()} {locales[lang]["COPYRIGHT_TEXT"]}
          </Text>
          <Text
            fontSize={device === "mobile" ? "3.8647vw" : "13px"}
            color={CLEAR_100}
          >
            {Parser(locales[lang]["MADE_IN_BH"])}
          </Text>
        </Flex>
      </Container>
    </Flex>
  )
}

export default Footer

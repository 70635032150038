import { keyframes } from "styled-components"

export default keyframes`
  0% {
    margin-left: -30vw;
  }

  35% {
    margin-left: -30vw;
  }

  70% {
    margin-left: 9.6619vw;
  }

  85% {
    margin-left: 9.6619vw;
  }

  100% {
    margin-left: 0;
  }
`

const BLUE_500 = "#00d6ff"

const CLEAR_100 = "#ffffff"

const DARK_900 = "#000000"

const GRAY_100 = "#f5f5f5"
const GRAY_300 = "#f2f2f2"
const GRAY_500 = "#dcdcdc"
const GRAY_600 = "#acacac"
const GRAY_650 = "#686868"
const GRAY_700 = "#525252"
const GRAY_900 = "#3c3e47"

const GREEN_500 = "#4be66a"

const ORANGE_500 = "#ff9023"

const YELLOW_100 = "#FCEFD5"
const YELLOW_500 = "#F8B200"

const PURPLE_50 = "#FAF1FD"
const PURPLE_100 = "#F1DCF9"
const PURPLE_300 = "#DDA8EF"
const PURPLE_500 = "#C827FF"
const PURPLE_700 = "#951CC6"

export {
  BLUE_500,
  CLEAR_100,
  DARK_900,
  GRAY_100,
  GRAY_300,
  GRAY_500,
  GRAY_600,
  GRAY_650,
  GRAY_700,
  GRAY_900,
  GREEN_500,
  ORANGE_500,
  PURPLE_50,
  PURPLE_100,
  PURPLE_300,
  PURPLE_500,
  PURPLE_700,
  YELLOW_100,
  YELLOW_500,
}

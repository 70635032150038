import React, { useEffect, useRef, useState } from "react"
import styled, { css, keyframes } from "styled-components"

/** Auxiliaries */
import {
  Button,
  Container,
  Flex,
  Icon,
  Link,
  Text,
  Title,
} from "../../auxiliaries"
import { Appear, SlideFromLeft } from "../../auxiliaries/Mixins"

/** Utils */
import range from "../../../utils/functional/range"
import { CLEAR_100, DARK_900 } from "../../../utils/layout/COLORS"

/** Locales */
import locales from "./locales/Navbar.json"
import { navigate } from "gatsby"

const Mobile = ({ lang = "PT" }) => {
  const [toggle, setToggle] = useState(false)
  const [animate, setAnimate] = useState(false)
  const [animation, setAnimation] = useState("")
  const ref = useRef()

  const onClick = link => {
    const element = document.getElementById(link)

    if (ref && ref.current) {
      setToggle(false)

      window.scrollTo({
        top: element.offsetTop - ref.current.offsetHeight - 16,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    if (toggle && !animate) {
      setAnimation("in")
      setTimeout(() => setAnimate(true), 700)
    } else if (!toggle && animate) {
      setAnimation("out")
      setTimeout(() => setAnimate(false), 300)
    } else {
      setAnimation("")
    }
  }, [toggle, animate])

  return (
    <Flex position="relative" height="26.063826087vw">
      <Flex
        ref={ref}
        position="fixed"
        bgColor={CLEAR_100}
        style={{
          left: "0px",
          top: "0px",
          zIndex: "10",
          width: "100%",
        }}
      >
        <Container
          delimiter="80.676328502vw"
          justifyContent="space-between"
          alignItems="center"
          bgColor={CLEAR_100}
          py="8vw"
          mx="auto"
        >
          <Icon
            name="logo"
            height="7.729468599vw"
            width="6.763285024vw"
            color={animate ? CLEAR_100 : DARK_900}
            style={{ zIndex: "10" }}
            cursor="pointer"
            onClick={() => navigate("/")}
          />
          <MenuToggle toggle={animate}>
            <input
              type="checkbox"
              id="menu-toggle"
              onChange={() => setToggle(t => !t)}
              checked={toggle}
            />
            <label htmlFor="menu-toggle">
              <div style={{ zIndex: "10" }} />
            </label>
            <div />
          </MenuToggle>
          {toggle && (
            <Flex
              direction="column"
              gap="42.753623188vw"
              position="fixed"
              width="80.676328502vw"
              style={{
                top: "45.410628019vw",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <AnimatedTitle
                animation={animation}
                fontFamily="Raleway"
                fontSize="9.661835749vw"
                weight="black"
                color={CLEAR_100}
              >
                Menu
              </AnimatedTitle>
              <Flex direction="column" gap="7.729468599vw">
                {/* <AnimatedText
                  onClick={() => navigate("/vps")}
                  animation={animation}
                  fontFamily="Raleway"
                  fontSize="3.8647vw"
                  weight="medium"
                  color={CLEAR_100}
                >
                  {locales[lang]["VPS"]}
                </AnimatedText> */}
                <AnimatedText
                  onClick={() => navigate("/baremetal")}
                  animation={animation}
                  fontFamily="Raleway"
                  fontSize="3.8647vw"
                  weight="medium"
                  color={CLEAR_100}
                >
                  {locales[lang]["BAREMETAL"]}
                </AnimatedText>
                <AnimatedText
                  onClick={() => navigate("/colocation")}
                  animation={animation}
                  fontFamily="Raleway"
                  fontSize="3.8647vw"
                  weight="medium"
                  color={CLEAR_100}
                >
                  {locales[lang]["COLOCATION"]}
                </AnimatedText>
              </Flex>
              <Flex direction="column" gap="8.937198068vw">
                <Link href="https://enxadahost.com/members/login" target="_BLANK">
                  <AnimatedButton
                    animation={animation}
                    bgColor={CLEAR_100}
                    width="100%"
                  >
                    <Text
                      fontFamily="Raleway"
                      fontSize="3.8647343vw"
                      weight="bold"
                      color={DARK_900}
                      py="4.347826087vw"
                      px="auto"
                    >
                      {locales[lang]["LOGIN"]}
                    </Text>
                  </AnimatedButton>
                </Link>
                <Link href="https://enxadahost.com/members/register.php" target="_BLANK">
                  <AnimatedButton
                    animation={animation}
                    bgColor={DARK_900}
                    width="100%"
                  >
                    <Text
                      fontFamily="Raleway"
                      fontSize="3.8647343vw"
                      weight="bold"
                      color={CLEAR_100}
                      py="4.347826087vw"
                      px="auto"
                    >
                      {locales[lang]["SIGNUP"]}
                    </Text>
                  </AnimatedButton>
                </Link>
              </Flex>
            </Flex>
          )}
        </Container>
      </Flex>
    </Flex>
  )
}

const generateAnimationPercentages = (hasBg = true) =>
  range(100)
    .map(
      i => `${40 + i * 0.6}% {
  ${hasBg
          ? `background: linear-gradient(to right, ${CLEAR_100} ${i}%, ${DARK_900} ${i}%);`
          : `background: transparent;`
        }
}`
    )
    .join(`\n\n`)

const SlideButtonBackgroundFromLeft = bgColor => keyframes`
  0% {
    background: transparent;
  }

  40% {
    background: transparent;
  }

  ${generateAnimationPercentages(bgColor === CLEAR_100)}
`

const AnimatedButton = styled(Button)`
  /* animation: ${({ animation, bgColor }) =>
    animation &&
    animation === "in" &&
    css`0.7s ${SlideButtonBackgroundFromLeft} infinite;`}; */

  ${({ animation, bgColor }) =>
    animation &&
    css`
      animation: ${animation === "in" &&
      css`0.7s ${SlideButtonBackgroundFromLeft(bgColor)} ease-in;`};

      & > p {
        animation: ${animation === "in" && css`0.7s ${Appear} ease-in;`};
      }
    `}
`

const AnimatedTitle = styled(Title)`
  animation: ${({ animation }) =>
    animation && animation === "in" && css`0.7s ${SlideFromLeft} ease-out`};
`

const AnimatedText = styled(Text)`
  animation: ${({ animation }) =>
    animation && animation === "in" && css`0.7s ${SlideFromLeft} ease-out`};
`

const MenuToggle = styled.div`
  height: 5.6352657vw;
  width: 6.442vw;
  position: relative;

  & > input {
    display: none;
  }

  & > label > div {
    position: relative;
    margin: 2.22705314vw 0;
    height: 1.18115942vw;
    width: 6.442028986vw;
    background-color: ${({ toggle }) => (toggle ? CLEAR_100 : DARK_900)};
    transition: all 0.5s;

    &:before {
      content: "";

      position: absolute;
      left: 0;
      top: -2.22705314vw;
      height: 1.18115942vw;
      width: 6.442028986vw;
      background-color: ${({ toggle }) => (toggle ? CLEAR_100 : DARK_900)};
      transition: all 0.5s;
    }

    &:after {
      content: "";

      position: absolute;
      left: 0;
      top: 2.22705314vw;
      height: 1.18115942vw;
      width: 6.442028986vw;
      background-color: ${({ toggle }) => (toggle ? CLEAR_100 : DARK_900)};
      transition: all 0.5s;
    }
  }

  & > input ~ div {
    background-color: ${DARK_900};
    top: 0;
    left: 100%;
    width: 100vw;
    height: 100vh;
    position: fixed;
    transition: all 0.5s;
  }

  & > input:checked ~ div {
    left: 0;
  }

  & > input:checked + label > div {
    background-color: transparent;

    &:before {
      top: 0;
      transform: rotate(135deg);
    }

    &:after {
      top: 0;
      transform: rotate(-135deg);
    }
  }
`

export default Mobile

import React, { createContext, useContext, useState, useEffect } from "react"

const detectWindowSize = (window) => {
  if (typeof window === 'object') {
    if (window.matchMedia("screen and (max-width: 480px)").matches) {
      return "mobile"
    } else if (window.matchMedia("screen and (max-width: 768px)").matches) {
      return "tablet"
    } else if (window.matchMedia("screen and (max-width: 1024px)").matches) {
      return "laptop"
    } else if (window.matchMedia("screen and (min-width: 1024px)").matches) {
      return "desktop"
    }
  }

  return ""
}

const deviceTypeContext = createContext({})

const DeviceTypeProvider = ({ children }) => {
  const [device, setDevice] = useState(detectWindowSize())

  const onWindowResize = () => setDevice(detectWindowSize(window))

  useEffect(() => {
    setDevice(detectWindowSize(window))
    window.addEventListener("resize", () => onWindowResize)
    return () => window.removeEventListener("resize", onWindowResize)
  }, [])

  return (
    <deviceTypeContext.Provider value={{ device }}>
      {children}
    </deviceTypeContext.Provider>
  )
}

const useDeviceType = () => {
  const { device } = useContext(deviceTypeContext)
  return device
}

export { DeviceTypeProvider, useDeviceType }

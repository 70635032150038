import { keyframes } from "styled-components"

export default keyframes`
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
`

import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { HeightProps, WidthProps } from "."
import { transformStyleToStyleObj } from "../../utils/functional/parsers"

const StyledIcon = styled.svg`
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};

  g[fill],
  rect[fill],
  polygon[fill],
  line[fill],
  polyline[fill],
  circle[fill],
  path[fill],
  path {
    &:not([fill="none"]) {
      fill: ${props => props.fill || props.color};
    }
  }
  g[stroke],
  rect[stroke],
  polygon[stroke],
  line[stroke],
  polyline[stroke],
  circle[stroke],
  path[stroke] {
    &:not([stroke="none"]) {
      stroke: ${props => props.stroke || props.color};
      stroke-width: ${props => props.stroke_width};
    }
  }
  transform: ${props => props.rotate};

  ${HeightProps};
  ${WidthProps};
`

const IconDiv = styled.div`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const Icon = ({ name, ...props }) => {
  const iconRef = useRef(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    const load = async () => {
      iconRef.current = (
        await import(`!svg-loader!../../assets/svg/${name}.svg`)
      ).default

      setLoading(false)
    }

    load()
  }, [name])

  if (!loading && iconRef.current) {
    const { content, attributes } = iconRef.current
    const { class: className, style, ...filteredAttributes } = attributes

    if (style) {
      if (props.style) {
        props.style = { ...props.style, ...transformStyleToStyleObj(style) }
      } else {
        props.style = transformStyleToStyleObj(style)
      }
    }
    return (
      <StyledIcon
        dangerouslySetInnerHTML={{ __html: content }}
        {...{ ...filteredAttributes, ...props, ...{ className } }}
      />
    )
  }

  return (props.width && props.height) ? <IconDiv width={props.width} height={props.height} /> : null
}

export default Icon

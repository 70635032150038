import React, { useEffect } from "react"

/** Utils */
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Components */
import Desktop from "./Desktop"
import Mobile from "./Mobile"

const deviceComponent = {
  mobile: Mobile,
  tablet: Desktop,
  laptop: Desktop,
  desktop: Desktop,
};

const Navbar = () => {
  const device = useDeviceType()
  const lang = useLang()

  if (!device) return null

  const Component = deviceComponent[device];

  return Component ? <Component lang={lang} /> : null;
}

export default Navbar

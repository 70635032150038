import React from "react"

/** Auxiliaries */
import {
  Button,
  Container,
  Flex,
  Icon,
  Link,
  Text,
  Title,
} from "../../auxiliaries"

/** Utils */
import { CLEAR_100, PURPLE_700 } from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Locales */
import locales from "./locales/Help.json"

const Help = () => {
  const device = useDeviceType()
  const lang = useLang()

  if (!device) return null

  return (
    <Flex id="help" bgColor={PURPLE_700}>
      <Container
        delimiter={device === "mobile" ? "80.676328502vw" : "80.555555556vw"}
        direction={device === "mobile" ? "column" : "row"}
        gap="8.333333333vw"
        mx={device === "mobile" && "auto"}
        ml={device !== "mobile" && "13.611111111vw"}
        pt={device === "mobile" ? "28.985507246vw" : "50px"}
        pb={device === "mobile" ? "28.985507246vw" : "50px"}
        alignItems="center"
      >
        <Container delimiter="112.083333333vw" direction="column">
          <Title
            fontFamily="Raleway"
            fontSize={device === "mobile" ? "9.6618vw" : "4em"}
            weight="black"
            color={CLEAR_100}
          >
            {locales[lang]["NEED_HELP"]}
          </Title>
          <Text
            fontSize={device === "mobile" ? "3.8647vw" : "1.111111111vw"}
            color={CLEAR_100}
            mt={device === "mobile" ? "5.7971vw" : "1.388888889vw"}
            mb={device === "mobile" ? "15.4589vw" : "1.944444444vw"}
          >
            {locales[lang]["HOW_TO_GET_HELP"]}
          </Text>
          <Flex
            direction={device === "mobile" ? "column" : "row"}
            gap={device === "mobile" ? "9.6618vw" : "2.222222222vw"}
          >
            <Flex justifyContent="center">
              <Link href="https://ajuda.enxadahost.com" target="_BLANK">
                <Button
                  bgColor={CLEAR_100}
                  px={device === "mobile" && "5.7971vw"}
                  py={device === "mobile" && "4.347826087vw"}
                >
                  <Text
                    fontFamily="Raleway"
                    fontSize={device === "mobile" ? "3.8647vw" : "16px"}
                    color={PURPLE_700}
                  >
                    {locales[lang]["ACCESS_HELP_PAGE"]}
                  </Text>
                </Button>
              </Link>
            </Flex>
            <Flex justifyContent="center">
              <Link href="https://wa.me/5561991196052/?text=Vim+pela+EnxHost!">
                <Button
                  bgColor={PURPLE_700}
                  px={device === "mobile" && "5.7971vw"}
                  py={device === "mobile" && "4.347826087vw"}
                >
                  <Text
                    fontFamily="Raleway"
                    fontSize={device === "mobile" ? "3.8647vw" : "16px"}
                    color={CLEAR_100}
                  >
                    {locales[lang]["GET_IN_TOUCH"]}
                  </Text>
                </Button>
              </Link>
            </Flex>
          </Flex>
        </Container>
        <Icon
          name="mail"
          height={device === "mobile" ? "43.961352657vw" : "20.277777778vw"}
          width={device === "mobile" ? "80.676328502vw" : "37.013888889vw"}
        />
      </Container>
    </Flex>
  )
}

export default Help

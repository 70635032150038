import React, { createContext, useContext, useEffect, useState } from "react"

const validLanguages = ['PT']

const updateLanguage = (lang) => {
  let language = lang

  if (typeof lang === 'string') {
    language = validLanguages.includes(language.toUpperCase()) ? language.toUpperCase() : validLanguages[0]
  } else {
    language = validLanguages[0]
  }

  return language
}

const detectLanguageFromURL = (location) => {
  const validUrlLangs = ['pt-BR']
  const urlLang = location?.pathname?.substring(1)?.split('/')[0]

  if (validUrlLangs.includes(urlLang)) {
    return urlLang?.substring(0, 2).toUpperCase()
  }

  return null
}

const getBrowserLang = () => {
  if (typeof navigator === 'object') {
    const browserLang = navigator?.language?.substring(0, 2)?.toUpperCase()

    if (validLanguages.includes(browserLang)) {
      return browserLang
    }
  }

  return validLanguages[0]
}

const langContext = createContext({})

const LangProvider = ({ location, children }) => {
  const [language, setLanguage] = useState(getBrowserLang())

  useEffect(() => {
    if (location) {
      const urlLang = detectLanguageFromURL(location)

      !!urlLang && setLanguage(urlLang)
    }
  }, [location])

  const update = (newLang) => setLanguage(updateLanguage(newLang))

  return (
    <langContext.Provider value={{ language, update }}>
      {children}
    </langContext.Provider>
  )
}

const useLang = () => {
  const { language } = useContext(langContext)
  return language
}

const useUpdateLang = () => {
  const { update } = useContext(langContext)
  return update
}

export { LangProvider, useLang, useUpdateLang }

import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`   
  * {
    box-sizing: border-box;
    outline: 0;
    border: 0;
    margin: 0;
    padding: 0;
  }

  body {
    overflow-x: hidden;
  }
`
